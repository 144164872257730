import React from "react";
import ContactContent from "./ContactContent";
import "./contact.css";
const Contact = () => {
  return (
    <div className="container-fluid bg-degraded py-4">
      <ContactContent />
    </div>
  );
};

export default Contact;
