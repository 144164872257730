import React from "react";
import "./services.css";
import ServicesContent from "./ServicesContent";
const Services = () => {
  return (
    <div className="container-fluid bg-white pt-3 pb-2" id="services">
      <ServicesContent />
    </div>
  );
};

export default Services;
