import React from "react";
import AboutUsContent from "./AboutUsContent";
import "./aboutUs.css";
import BackgroundStart from "../../assets/images/day/About-backgroundB.png";
import BackgroundEnd from "../../assets/images/day/About-backgroundA.png";
import PaperPlane from "../../assets/images/day/Plane.png";

const AboutUs = () => {
  return (
    <div className="container-fluid bg-dark-blue pt-responsive-about px-0" id="aboutUs">
      <div className="bg-img absolute">
        <img
          className="background-start relative"
          src={BackgroundStart}
          alt="Background lines"
        />
      </div>
      <AboutUsContent />
      <div className="relative">
        <img className="absolute plane" src={PaperPlane} alt="Paper plane" />
        <div className="bg-img">
          <img
            className="background-end"
            src={BackgroundEnd}
            alt="Background lines"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
