import React, { useContext, useEffect, useState } from 'react';
import LogoMobile from '../../../assets/images/day/Logo-phone.png';
import Logo from '../../../assets/images/day/Logo--menu.png';
import English from '../../../assets/images/day/united-kingdom.png';
import Spanish from '../../../assets/images/day/spain.png';
import './header.css';

import { FormattedMessage } from 'react-intl';
import { langContext } from '../../../context/langContext';
import { mobileDetect } from '../../../library';

const HeaderContent = () => {
  const language = useContext(langContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(mobileDetect.mobile() !== null);
  }, []);

  const logoSrc = isMobile ? LogoMobile : Logo;
  let showDarkModeSwitch = false; //Activate when dark mode has been developed
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <div className="col-sm-3">
          <a className="ms-4" href="#home">
            <img className="logo" src={logoSrc} alt="Propel Software US Logo - Innovative software development" />
          </a>
        </div>

        {isMobile && showDarkModeSwitch && (
          <div className="d-flex col justify-content-end">
            <div className="form-check form-switch pe-5">
              <input className="form-check-input toggle-button" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
        )}

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <div className="col-sm-12">
            <div className="row justify-content-between align-items-center">
              <div className="col-sm-8">
                <ul className="gothamlight text-size tw-bold navbar-nav me-auto mb-2 mb-lg-0 justify-content-center">
                  <div className="col-lg-12 d-flex responsive-header justify-content-between pe-5">
                    <li className="nav-item">
                      <a className="gothambold nav-link active" aria-current="page" href="#home">
                        <FormattedMessage id="menu.home" defaultMessage="Home" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#aboutUs">
                        <FormattedMessage id="menu.aboutUs" defaultMessage="About Us" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#services">
                        <FormattedMessage id="menu.services" defaultMessage="Services" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#offer">
                        <FormattedMessage id="menu.offer" defaultMessage="Offer" />
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#contact">
                        <FormattedMessage id="menu.contact" defaultMessage="Contact" />
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a className="language" onClick={() => language.setLang('en-US')}>
                        <img width="30px" src={English} alt="united kindom flag" />
                      </a>
                      <a className="language ms-3" onClick={() => language.setLang('es-AR')}>
                        <img width="30px" src={Spanish} alt="spain flag" />
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
              <div className="col-sm-2">
                {!isMobile && showDarkModeSwitch && (
                  <div className="d-flex col-sm-1 justify-content-start align-items-center">
                    <div className="form-check form-switch">
                      <input className="form-check-input toggle-button" type="checkbox" id="flexSwitchCheckDefault" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderContent;
