import React from "react";
import { FormattedMessage } from "react-intl";
import Developing from "../../assets/images/day/Developing.png";
import Introduction from "../../assets/images/day/Introduction.png";
import Outcome from "../../assets/images/day/Outcome.png";

const OfferContent = () => {
  return (
    <div className="mt-4">
      <p className="gothambold font-tittle text-uppercase text-center text-darkness spacing">
        <FormattedMessage id="offer.tittle" defaultMessage="Offer" />
      </p>
      <div className="row justify-content-center">
        <div className="col-sm-12 col-md-6">
          <p className="gothamlight fw-bolder font-offer-primary text-center text-dark-blue">
            <FormattedMessage
              id="offer.primary"
              defaultMessage='We help our clients transform "remote ideas" into'
            />{" "}
            <span className="gothambold">
              <FormattedMessage
                id="offer.primaryBold"
                defaultMessage="big projects."
              />
            </span>
          </p>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-2 justify-content-around align-iteml-center mx-responsive">
        <div className="col">
          <div className="card shadow-sm border-0 rounded-card">
            <div className="text-center mt-5">
              <img
                src={Introduction}
                className="single-simbol-img"
                alt="code opening symbol"
              />
            </div>
            <div className="card-body my-3 mx-4">
              <p className="title-card gothambold text-center text-dark-blue">
                <FormattedMessage
                  id="offer.product1.tittle"
                  defaultMessage="Introduction"
                />
              </p>
              <p className="body-text-card myriadpro-regular text-start text-darkness-100">
                <FormattedMessage
                  id="offer.product1.text"
                  defaultMessage="Agregar algun texto, amet consectetur adipisicing elit. Veniam delectus, quos aliquid labore , quos aliquid , quos a consectetur adipisicing elit dicta provident."
                />
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card shadow-sm border-0 rounded-card">
            <div className="text-center mt-5">
              <img
                src={Developing}
                className="double-simbol-img"
                alt="code opening symbol"
              />
            </div>
            <div className="card-body my-3 mx-4">
              <p className="title-card gothambold text-center text-dark-blue">
                <FormattedMessage
                  id="offer.product2.tittle"
                  defaultMessage="Development"
                />
              </p>
              <p className="body-text-card myriadpro-regular text-start text-darkness-100">
                <FormattedMessage
                  id="offer.product2.text"
                  defaultMessage="Agregar algun texto, amet consectetur adipisicing elit. Veniam delectus, quos aliquid labore , quos aliquid , quos a consectetur adipisicing elit dicta provident."
                />
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card shadow-sm border-0 rounded-card">
            <div className="text-center mt-5">
              <img
                src={Outcome}
                className="single-simbol-img"
                alt="code opening symbol"
              />
            </div>
            <div className="card-body my-3 mx-4">
              <p className="title-card gothambold text-center text-dark-blue">
                <FormattedMessage
                  id="offer.product3.tittle"
                  defaultMessage="Development"
                />
              </p>
              <p className="body-text-card myriadpro-regular text-start text-darkness-100">
                <FormattedMessage
                  id="offer.product3.text"
                  defaultMessage="Agregar algun texto, amet consectetur adipisicing elit. Veniam delectus, quos aliquid labore , quos aliquid , quos a consectetur adipisicing elit dicta provident."
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-2 justify-content-around align-iteml-center mx-responsive separate">
        <div className="col">
          <div className="card bg-pink shadow-sm border-0 rounded-card">
            <div className="text-center py-5">
              <p className="font-offer-primary gothambold text-center text-smoke-white-100 m-0">
                <FormattedMessage
                  id="offer.colorCard1.text"
                  defaultMessage="Compromise"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-purple-100 shadow-sm border-0 rounded-card">
            <div className="text-center py-5">
              <p className="font-offer-primary gothambold text-center text-smoke-white-100 m-0">
                <FormattedMessage
                  id="offer.colorCard2.text"
                  defaultMessage="Speed"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card bg-bluish-green shadow-sm border-0 rounded-card">
            <div className="text-center py-5">
              <p className="font-offer-primary gothambold text-center text-smoke-white-100 m-0">
                <FormattedMessage
                  id="offer.colorCard3.text"
                  defaultMessage="Efficiency"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferContent;
