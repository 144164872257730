import React from 'react';
import DividerContent from './DividerContent';
import './divider.css';

const Divider = (props) => {
  return (
    <div className="container-fluid bg-degraded py-4">
      <DividerContent {...props} />
    </div>
  );
};

export default Divider;
