import { hydrate, render } from 'react-dom';
import React from 'react';
import './index.css';
import './fonts.css';
import './colors.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { LangProvider } from './context/langContext';

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <LangProvider>
      <App />
    </LangProvider>,
    rootElement
  );
} else {
  render(
    <LangProvider>
      <App />
    </LangProvider>,
    rootElement
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
