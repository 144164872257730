import React from "react";
import WelcomeContent from "./WelcomeContent";
import "./welcome.css";

const Welcome = () => {
  return (
    <div className="container-fluid bg-gray pt-3 pb-5" id="home">
      <WelcomeContent />
    </div>
  );
};

export default Welcome;
