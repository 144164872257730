import React from "react";
import { FormattedMessage } from "react-intl";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import LogoInstagram from "../../assets/images/day/Instagram.png";
import LogoLinkedIn from "../../assets/images/day/Linkedin.png";

const ContactContent = () => {
  const sendEmail = (e) => {
    e.preventDefault();
    const lang = localStorage.getItem("lang");

    emailjs
      .sendForm(
        "infoPropelSoftware",
        "template_YTvLMalh",
        e.target,
        "user_6t7k0ll15yWn2BKXUgsZT"
      )
      .then(
        (result) => {
          let title = "Successful!";
          let message = "The email was sent successfully";

          if (lang === "es-AR") {
            title = "Exitoso!";
            message = "El email fue enviado correctamente";
          }
          Swal.fire({
            title: title,
            text: message,
            icon: "success",
            confirmButtonText: "Close",
          });
          e.target.reset();
        },
        (error) => {
          let title = "Error!";
          let message = "We sorry! The email could not be sent correctly.";

          if (lang === "es-AR") {
            message =
              "¡Lo lamentamos! El correo electrónico no se pudo enviar correctamente.";
          }
          Swal.fire({
            title: title,
            text: message,
            icon: "error",
            confirmButtonText: "Close",
          });
        }
      );
  };
  return (
    <div className="m-custom">
      <p className="gothambold font-tittle text-uppercase text-center text-smoke-white-100 spacing">
        <FormattedMessage id="contact.tittle" defaultMessage="Contact" />
      </p>
      <div className="card shadow-sm border-0 rounded-card my-card">
        <div className="card-body my-3 mx-4 px-0">
          <div className="mx-form-custom">
            <form className="row g-3" onSubmit={sendEmail}>
              <div className="col-sm-12 col-md-6">
                <label
                  htmlFor="validationDefault01"
                  className="form-label myriadpro-bold text-start text-darkness-100"
                >
                  <FormattedMessage
                    id="contact.companyName"
                    defaultMessage="Contact"
                  />
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault01"
                  name="company_name"
                  required
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label
                  htmlFor="validationDefault02"
                  className="form-label myriadpro-bold text-start text-darkness-100"
                >
                  <FormattedMessage
                    id="contact.name"
                    defaultMessage="Contact"
                  />
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault02"
                  name="from_name"
                  required
                />
              </div>
              <div className="col-sm-12 col-md-6">
                <label
                  htmlFor="validationDefaultUsername"
                  className="form-label  myriadpro-bold text-start text-darkness-100"
                >
                  <FormattedMessage id="contact.email" defaultMessage="Email" />
                  *
                </label>
                <div className="input-group">
                  <span className="input-group-text" id="inputGroupPrepend2">
                    @
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="validationDefaultUsername"
                    aria-describedby="inputGroupPrepend2"
                    name="from_email"
                    required
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <label
                  htmlFor="validationDefault03"
                  className="form-label  myriadpro-bold text-start text-darkness-100"
                >
                  <FormattedMessage
                    id="contact.phoneNumber"
                    defaultMessage="Phone number"
                  />
                  *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationDefault03"
                  name="phone"
                  required
                />
              </div>
              <div className="col-sm-12">
                <label
                  htmlFor="validationDefault03"
                  className="form-label myriadpro-bold text-start text-darkness-100"
                >
                  <FormattedMessage
                    id="contact.tellUs"
                    defaultMessage="Tell us about your project"
                  />
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="message_html"
                ></textarea>
              </div>
              <div className="col-sm-12 col-12">
                <div className="d-flex mt-4 justify-content-between align-items-center">
                  <div className="d-flex social-row">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/propelsoftware/"
                    >
                      <img
                        className="social-network me-3"
                        src={LogoInstagram}
                        alt="Instagram PropelSoftware us logo"
                      />
                    </a>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/in/casasfranco/"
                    >
                      <img
                        className="social-network me-3"
                        src={LogoLinkedIn}
                        alt="Logo LinkedIn"
                      />
                    </a>
                  </div>

                  <button
                    className="myriadpro-bold send-buttom box"
                    type="submit"
                  >
                    <span className="text-degraded text-buttom myriadpro-bold fw-bold text-uppercase">
                      <FormattedMessage
                        id="contact.send"
                        defaultMessage="Send"
                      />
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <p className="body-text-card myriadpro-bold text-start text-darkness-100"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactContent;
