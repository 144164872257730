import React from "react";
import FooterContent from "./FooterContent";
import "./footer.css";

function Footer() {
  return (
    <footer className="bg-dark-blue">
      <FooterContent />
    </footer>
  );
}

export default Footer;
