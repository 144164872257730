import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import JoseanlandscapingWork from '../../assets/images/works/joseanlandscaping.png';
import { mobileDetect } from '../../library';

const WorksContent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(mobileDetect.mobile() !== null);
  }, []);

  return (
    <div className="my-4">
      <p className="gothambold font-tittle text-uppercase text-center text-darkness spacing">
        <FormattedMessage id="works.tittle" defaultMessage="Works" />
      </p>
      <section className="d-flex flex-wrap justify-content-around gap-10">
        <div className={`${isMobile ? 'w-full' : 'w-25 my-2'} mx-4 hvr-grow text-center`}>
          <a href="https://joseanlandscaping.com" target="_blank" rel="noopener noreferrer">
            <img src={JoseanlandscapingWork} alt="Client Website Josean Landscaping - Professional landscaping and gardening services" className="rounded-3 w-100" />
          </a>
          <p className="font-bold text-lg mt-2 text-dark-blue mb-0 gothambold">Josean Landscaping</p>
          <a href="https://joseanlandscaping.com" target="_blank" rel="noopener noreferrer" className="text-sm">
            Visit website
          </a>
        </div>
      </section>
    </div>
  );
};

export default WorksContent;
