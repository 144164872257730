import React from "react";
import OfferContent from "./OfferContent";
import "./offer.css";
const Offer = () => {
  return (
    <div className="container-fluid bg-gray pt-3 pb-5" id="offer">
      <OfferContent />
    </div>
  );
};

export default Offer;
