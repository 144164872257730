import React from "react";
import Rocket from "../../assets/images/day/Rocket.png";
import LogoAbout from "../../assets/images/day/Logo-about.png";
import { FormattedMessage } from "react-intl";

const AboutUsContent = () => {
  return (
    <div className="pb-custom pt-4">
      <div className="relative">
        <img className="absolute rocket" src={Rocket} alt="Rocket" />
      </div>
      <p className="font-about text-uppercase gothambold text-center text-smoke-white spacing">
        <FormattedMessage id="aboutUs.tittle" defaultMessage="about us" />
      </p>
      <div className="row direction justify-content-center mt-4 align-items-center mx-0">
        <div className="col-sm-6 col-md-8 col-lg-5 text-center">
          <p className="text-smoke-white-100 gothambold font-about-principal mt-2 text-center px-4">
            <FormattedMessage
              id="aboutUs.primary"
              defaultMessage="We offer the boost that your company needs,"
            />
            <span className="gothamlight">
              {" "}
              <FormattedMessage
                id="aboutUs.primaryBold"
                defaultMessage="reflected in the development of specific and personalized software."
              />
            </span>
          </p>
        </div>
      </div>
      <div className="row mt-custom mx-0 align-items-center">
        <div className="col-sm-12 col-md-3 col-lg-4 col-xl-4 mt-3 mb-5">
          <div className="text-center">
            <img
              className="logo-about"
              src={LogoAbout}
              alt="Logo Propel Software"
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-3 p-custom ms-custom">
          <div className="text-smoke-white font-about-secondary px-custom myriadpro-regular text-start ">
            <p>
              <FormattedMessage
                id="aboutUs.secondary.center"
                defaultMessage="We are a company dedicated to generating specific software, our purpose is to satisfy the needs that our clients pose."
              />
            </p>
            <p>
              <FormattedMessage
                id="aboutUs.secondary.center2"
                defaultMessage="We love challenges and we are prepared to face them."
              />
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 pe-custom align-self-start ms-custom">
          <div className="text-smoke-white font-about-secondary px-custom text-start">
            <p className="myriadpro-bold">
              <FormattedMessage
                id="aboutUs.secondary.right"
                defaultMessage="We really want you to grow with us."
              />
              <br />
              <span className="myriadpro-regular">
                <FormattedMessage
                  id="aboutUs.secondary.rightBold"
                  defaultMessage="We focus on becoming support and assistance to our clients."
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUsContent;
