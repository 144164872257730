import React from 'react';
import Logo from '../../../assets/images/day/Logo-footer.png';
import Phone from '../../../assets/images/day/Phone.png';
import Location from '../../../assets/images/day/Location.png';
import { FormattedMessage } from 'react-intl';

function FooterContent() {
  return (
    <div className=" text-white container-footer">
      <div className="row mx-0 row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 align-item-custom">
        <div className="col d-flex justify-content-custom">
          <div className="text-custom">
            <p className="gothambold title-footer">Propel Software</p>
            <div className="myriadpro-regular body-text-footer">
              <p>
                <FormattedMessage id="menu.home" defaultMessage="Home" />
              </p>
              <p>
                <FormattedMessage id="menu.aboutUs" defaultMessage="About Us" />
              </p>
              <p>
                <FormattedMessage id="menu.services" defaultMessage="Services" />
              </p>
              <p>
                <FormattedMessage id="menu.offer" defaultMessage="Offer" />
              </p>
              <p className="mb-0">
                <FormattedMessage id="menu.contact" defaultMessage="Contact" />
              </p>
            </div>
          </div>
        </div>
        <div className="col d-flex justify-content-custom">
          <div className="text-custom">
            <p className="gothambold title-footer">
              <FormattedMessage id="footer.contact" defaultMessage="Contact" />
            </p>
            <div className="myriadpro-regular body-text-footer">
              <div className="d-flex align-items-center">
                <img className="phone-icon" src={Phone} alt="phone icon" />
                <a className="ms-2 a-footer" href="tel:+17863540117">
                  +1 (786) 354-0117
                </a>
              </div>
              <p className="mt-1 mb-0">info@propelsoftware.com</p>
            </div>
          </div>
        </div>
        <div className="col d-flex justify-content-custom">
          <div className="text-custom">
            <p className="gothambold title-footer">
              <FormattedMessage id="footer.location" defaultMessage="Location" />
            </p>
            <div className="myriadpro-regular body-text-footer">
              <div className="d-flex flex-row">
                <div className="col-sm-1"></div>
              </div>
              <a className="a-footer" href="https://goo.gl/maps/o2pWSYgyDqqnAJbu5">
                <div className="d-flex align-items-center">
                  <img className="location-icon" src={Location} alt="location icon" />
                  <p className="ms-2 mb-0">8958 SW 150th Court Cir W</p>
                </div>
                <p className="ms-2 mb-0">Miami, Florida, United States</p>
              </a>
            </div>
          </div>
        </div>
        <div className="col d-flex justify-content-custom">
          <div className="text-custom">
            <p className="gothambold title-footer">
              <FormattedMessage id="footer.createdBy" defaultMessage="Created by" />
            </p>
            <img className="logo-footer" src={Logo} alt="Propel Software US Logo - Innovative software development" />
          </div>
        </div>
      </div>
      <p className="text-center copyright myriadpro-regular">
        &copy; 2021 <FormattedMessage id="footer.copyright" defaultMessage="All rights reserved." />
      </p>
    </div>
  );
}

export default FooterContent;
