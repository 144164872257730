import React from "react";
import { FormattedMessage } from "react-intl";
import ProductWhite from "../../assets/images/day/BoxA.png";
import ProductBlue from "../../assets/images/day/BoxB.png";

const ServicesContent = () => {
  const mql = window.matchMedia("(max-width: 600px)");

  let mobileView = mql.matches;
  return (
    <div className="mt-4">
      <p className="gothambold font-tittle text-uppercase text-center text-darkness spacing">
        <FormattedMessage id="services.tittle" defaultMessage="Services" />
      </p>
      <div className="row justify-content-around mt-4 align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          <div className="gothamlight fw-bolder text-dark-blue font-services-primary px-custom text-start ">
            <p>
              <FormattedMessage
                id="services.primary"
                defaultMessage="We design software"
              />
              <span className="gothambold">
                {" "}
                <br />
                <FormattedMessage
                  id="services.primaryBold"
                  defaultMessage="100% customized."
                />
              </span>
            </p>
          </div>
          <div className="myriadpro-regular text-darkness-100 font-services-secondary px-custom text-start mt-4">
            <p>
              <FormattedMessage
                id="services.primary.paragraph"
                defaultMessage='Our experience and knowledge allows us to offer a "integral construction" of the product of your need. It not only includes development, but also professional user interface designs.'
              />
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          <div className="text-center mt-2 mb-custom">
            <img
              className="product-box"
              src={ProductWhite}
              alt="Propel Software development buy product"
            />
          </div>
        </div>
      </div>
      <div className="row direction justify-content-around align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          <div className="gothamlight fw-bolder text-dark-blue font-services-primary px-custom text-start">
            <p>
              <FormattedMessage
                id="services.secondary"
                defaultMessage="We develop functionality"
              />
              <span className="gothambold">
                {" "}
                {!mobileView && <br />}
                <FormattedMessage
                  id="services.secondaryBold"
                  defaultMessage="100% effective"
                />
              </span>
            </p>
          </div>
          <div className="myriadpro-regular text-darkness-100 font-services-secondary px-custom text-start mt-4">
            <p>
              <FormattedMessage
                id="services.secondary.paragraph"
                defaultMessage="Through a meticulous study, we understand the motivations and real needs of customers, to result in products that generate real value for their user, placing emphasis on perfectly complying with the correct usability of the product."
              />
            </p>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          <div className="text-center mt-2">
            <img
              className="product-box"
              src={ProductBlue}
              alt="Propel Software development buy product"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesContent;
