import React from 'react';
import AboutUs from '../AboutUs';
import Divider from '../Divider';
import Offer from '../Offer';
import Services from '../Services';
import Welcome from '../Welcome';
import Contact from '../Contact';
import Works from '../Works';

const MainPage = () => {
  return (
    <>
      <Welcome />
      <AboutUs />
      <Services />
      <Divider secondary={false} />
      <Offer />
      <Divider secondary={true} />
      <Works />
      <Contact />
    </>
  );
};

export default MainPage;
