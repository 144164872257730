import React from 'react';
import { FormattedMessage } from 'react-intl';

const DividerContent = ({ secondary = false }) => {
  return (
    <div className="gothamlight text-smoke-white-100 text-center">
      <p className="font-divider-primary mb-0 px-custom">
        <FormattedMessage id={secondary ? 'divider.secondary' : 'divider.primary'} defaultMessage="We are responsible for" />{' '}
        <span className="gothambold">
          <FormattedMessage id={secondary ? 'divider.secondaryBold' : 'divider.primaryBold'} defaultMessage="making all your ideas come true." />
        </span>
      </p>
    </div>
  );
};

export default DividerContent;
