import React from "react";
import "./works.css";
import WorksContent from "./WorksContent";
const Works = () => {
  return (
    <div className="container-fluid bg-white pt-3 pb-2 d-flex justify-content-around" id="works">
      <WorksContent />
    </div>
  );
};

export default Works;
