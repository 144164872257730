import React from "react";
import { FormattedMessage } from "react-intl";
import MacbookAir from "../../assets/images/day/Macbookair.png";

const WelcomeContent = () => {
  return (
    <div className="mt-4">
      <p className="gothambold font-tittle text-uppercase text-center text-darkness spacing">
        <FormattedMessage id="welcome.tittle" defaultMessage="welcome" />
      </p>
      <div className="row justify-content-center mt-4 direction align-items-center">
        <div className="col-sm-12 col-md-5 col-lg-6 px-0">
          <div className="text-center mt-2 mb-custom">
            <img
              className="mac-book-air"
              src={MacbookAir}
              alt="MacBook Air Propel Software"
            />
          </div>
        </div>
        <div className="col-sm-2 col-md-7 col-lg-5 ">
          <p className="gothamlight fw-bolder text-dark-blue font-welcome-primary ms-2">
            <FormattedMessage
              id="welcome.primary"
              defaultMessage="We exponentially boost your business to another level, "
            />
            <span className="gothambold">
              {" "}
              <br />
              <FormattedMessage
                id="welcome.primaryBold"
                defaultMessage="grow with us."
              />
            </span>
          </p>
          <p className="myriadpro-regular text-darkness-100 font-welcome-secondary mb-3 ms-2">
            <FormattedMessage
              id="welcome.secondary"
              defaultMessage="We really want and provide the best to our clients, if your business grows, we grow with it."
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeContent;
