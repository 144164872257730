import React from 'react';
import HeaderContent from './HeaderContent';
import './header.css';

function Header() {
  return (
    <header className="bg-header py-2 shadow-lg">
      <HeaderContent />
    </header>
  );
}

export default Header;
