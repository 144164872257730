import React from "react";
import "./App.css";
import Header from "./Components/Common/Header";
import Footer from "./Components/Common/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import MainPage from "./Components/MainPage";

function App() {
  return (
    <div className="dark">
      <Router>
        <Header />
        <Switch>
          <Route path="/">
            <MainPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
